import  { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IconButton, Tooltip, Toolbar,Typography} from "@mui/material";
import { makeStyles} from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

import Search from '../../components/Search';
import { LinksContext } from '../../context/LinksContext';
import { useTranslation } from 'react-i18next';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: props => props.palette.secondary.light,
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const {t} = useTranslation()
  
    const {setLinkSearchValue, linkSearchValue} = useContext(LinksContext)
      
    const handleChangeSearch = e => {
      setLinkSearchValue(e.target.value);
    };
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t('selected')}
          </Typography>
        ) : (
          <div style={{display:"flex", padding: "10px", justifyContent: "space-between",
          width: "100%"}}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="span" style={{display: "contents"}}>
            {t('all_links')}
            </Typography>
            <Search handleChangeSearch={handleChangeSearch} searchValue={linkSearchValue} placeholder={t("search")}/>
          </div>
          
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list" >
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };