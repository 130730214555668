import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Toolbar,
  Typography, Divider
} from "@mui/material";
import AuthContext from "../../context/AuthContext";
import { NavLink } from "react-router-dom";
import LanguageFlags from "./LanguageFlag";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../../redux/actions";
import { Loader } from "../Loader";
 


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.spacing(12),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    height: theme.spacing(12),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  newHeight: {
    height: theme.spacing(12),
  },
  image: {
    height: theme.spacing(10),
  },
  avatar: {
  }

}));

export const TopBar = () => {
  const dispatch = useDispatch()
  const {firstname, lastname, login} = useSelector(state=>state.profileReducer)
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    dispatch(signOutAction())
   // auth.logout() // удалить, старый api 
  }
  const {t} = useTranslation()


  
  if(!login) return <Loader />

  return (
    <AppBar
      color="inherit"
      sx={{ bgcolor: 'background.paper' }}
      position="fixed"
      className={classes.appBar}
      height="50%"
    >
      <Toolbar display="flex" className={classes.newHeight}>
        <NavLink to="/dashboard" style={{ textDecoration: "none", cursor: "pointer"}}>
          <img
            mx="3rem"
            className={classes.image}
            src="/img/img_promo.png"
            alt="icon"
          />
        </NavLink>
        <Box mx={4}>
           <NavLink to="/dashboard" style={{ textDecoration: "none", cursor: "pointer"}}>
            <Typography variant="h4" color="primary" noWrap>
              Pixly.pro
            </Typography>
          </NavLink>
        </Box>

        <LanguageFlags/>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{padding: "0px"}}
        >
          <div style={{display: "table-row"}}>
            {firstname && <Typography component='h6' variant="h7" color="textPrimary" style={{ 
              padding: "10px 15px 0px 15px",
              fontWeight: "600",
              lineHeight: "1.57143",
              fontSize: "0.9rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"}}>
              {firstname} {lastname}
            </Typography >}
            <Typography component='p' variant="h7" color="textPrimary" style={{
              padding: "0px 15px 10px 15px",
              fontWeight: "400",
              lineHeight: "1.57143",
              fontSize: "0.9rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "rgb(99, 115, 129)" }}>
              {login}
            </Typography>
          </div>
          <Divider style={{margin: "5px 0px"}}/>
          <NavLink  to="/profile" style={{color: "inherit", textDecoration: "inherit"}}>
            <MenuItem onClick={handleClose}>
              {t('profile')}
            </MenuItem>
          </NavLink>
          <NavLink  to="/payment" style={{color: "inherit", textDecoration: "inherit"}}>
            <MenuItem onClick={handleClose}>
              {t('payment')}
            </MenuItem>
          </NavLink>
          <Divider style={{margin: "5px 0px"}}/>
          <MenuItem onClick={handleLogOut}>{t('logout')}</MenuItem>
        </Menu>
        <Box mx="auto" />
        <Box mx={3}>
          <Avatar color="info.main" onClick={handleClick} style={{ textDecoration: "none", cursor: "pointer"}}>
          {firstname[0]}{lastname[0]}
          </Avatar>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
