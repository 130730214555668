import React, { useState, useEffect } from "react"
import api from '../../redux/wsconnect'
import { Loader } from '../../components/Loader'
import { useSnackbar } from 'notistack'
import { Box, FormGroup, Typography, Link } from '@mui/material'
// import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PassInput from '../../components/Auth/PassInput'
import FormContainer from '../../components/Auth/FormContainer'
import BootstrapButton from '../../components/Auth/BootstrapButton'

import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'

export const ResetPassSuccess = () => {
  const [pass1, setPass1] = useState('')
  const [pass2, setPass2] = useState('')
  const [valid, setValid] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  useEffect(() => {
    if (pass1 === pass2 && pass1.length > 7) return setValid(true)
    setValid(false)
  }, [pass1, pass2])

  const changeHandler1 = (event) => {
    setPass1(event.target.value)
  };
  const changeHandler2 = (event) => {
    setPass2(event.target.value)
  };

  const pressHandler = async (event) => {
    if (event.key === "Enter" && valid === true) {
      savePass()
    }
  };


  // let id = useParams()
  // const id = new URLSearchParams(useLocation().search).get('id')
  const savePass = async () => {
    console.log(1)
    const data = await api("auth", "savePass", { id, password: pass1 })
    console.log(data)
    if (data instanceof Error) {
      enqueueSnackbar(data.message, { variant: 'error' })
    } else {
      enqueueSnackbar(data.message, { variant: 'success' })
      navigate("/login")
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  };


  useEffect(() => {
    if (id) {
      const checkPass = async () => {
          const data = await api("auth", "checkPass", id)
          if (data instanceof Error) {
            enqueueSnackbar(data.message, { variant: 'error' })
            navigate("/login")
          } else {
            setSuccess(true)
          }
      }
      checkPass()
    }
  }, [api, id, navigate, enqueueSnackbar])
  
  useEffect(()=> {
    if (!id) navigate("/login")
  }, [id, navigate])
  
  if (!success) return <Loader />
  
  return (
    <FormContainer>
      <Box
      component="form"
      noValidate
      autoComplete="off"
    >
      <Typography variant='h1'>Create new password</Typography>
      <Typography sx={{fontSize: 25}}>Please, don't use simple passwords</Typography>
      <FormGroup>
        <PassInput
          value={pass1}
          onChange={changeHandler1}
          onKeyPress={pressHandler}
          onClickIcon={() => setShowPassword(!showPassword)}
          onMousDownIcon={handleMouseDownPassword}
          showPassword={showPassword}
        />
      </FormGroup>
      <FormGroup>
        <PassInput
          value={pass2}
          onChange={changeHandler2}
          onKeyPress={pressHandler}
          onClickIcon={() => setShowPassword(!showPassword)}
          onMousDownIcon={handleMouseDownPassword}
          showPassword={showPassword}
        />
      </FormGroup>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, justifyContent: 'space-between' }}>      <BootstrapButton
        disabled={!valid}
        variant="outlined"
        onClick={savePass}
      >
        <Typography sx={{
          color: '#fff',
          letterSpacing: '0.02em',
          lineHeight: '26px',
          fontWeight: 500,
          fontSize: '18px',
        }}>Create new password</Typography>
      </BootstrapButton>
      <Typography align="right" variant="caption" display="block" gutterBottom sx={{
            fontSize: 18,
          }}>
          <Link component={RouterLink} underline="hover" to="/register">Sign Up</Link>
        </Typography>


        {/* <Button
          disabled={!valid || loading ? true : false}
          sx={{ mr: 20 }}
          variant="outlined"
          onClick={savePass}
          endIcon={<MailOutlineIcon />}
        >
          <Typography>Save Password</Typography>
        </Button> */}
          
      </Box>
    </Box>
    </FormContainer>
  )
};
