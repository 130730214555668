import {combineReducers} from 'redux'
import {profileReducer} from './profileReducer'
import {authReducer} from './authReducer'
import {domainReducer} from './domainReducer'

export const rootReducer = combineReducers({
    profileReducer: profileReducer,
    authReducer: authReducer,
    domainReducer: domainReducer

})