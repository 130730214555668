import { createContext, useReducer } from 'react'
import { reducer } from '../reducer/linksReducer'

const initialState = {
   links: [],
   linkSearchValue:'',
   count: 0,
   query: ''
}

export const LinksContext = createContext()

export const LinksContextProvider = ({ children }) => {
   const [value, dispatch] = useReducer(reducer, initialState)

   value.setLinks = (data) => {
      dispatch({ type: 'SET_LINKS_DATA', payload: data })
   }
   value.setCount = (data) => {
      dispatch({ type: 'SET_COUNT', payload: data })
   }
   value.setLinkSearchValue = (data) => {
      dispatch({ type: 'SET_SEARCH_VALUE', payload: data })
   }
   return <LinksContext.Provider value={value}>
      {children}
   </LinksContext.Provider>
}
