import {useEffect} from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LinksPage } from './pages/LinksPage/LinksPage'
import { CreatePage } from './pages/CreatePage/CreatePage'
import { DetailPage } from './pages/DetailPage/DetailPage'
import { AuthSignIn } from './pages/Auth/AuthSignIn'
import { AuthSignUp } from './pages/Auth/AuthSignUp'
import { ResetPass } from './pages/Auth/ResetPass'
import { Loader } from './components/Loader'
import { ResetPassSuccess } from './pages/Auth/ResetPassSuccess'
import { Domain } from './pages/Domain/Domain'
import ProfileNavBar from './pages/Profile/ProfileNavBar'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import { Payment } from './pages/Payment/Payment'

export const useRoutes = isLog => {
  if (isLog) {
    return (
      <Routes>
        <Route path="/links" exact element={<LinksPage />} />
        <Route path="/create" exact element={<CreatePage />} />
        <Route path="/detail/:id" element={<DetailPage />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/domain" element={<Domain />} />
        <Route path="/profile" element={<ProfileNavBar />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/*" element={<Navigate to="dashboard" replace />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/login" exact element={<AuthSignIn />} />
      <Route path="/register" exact element={<AuthSignUp />} />
      <Route path="/reset-pass" exact element={<ResetPass />} />
      <Route path="/reset-pass-success" exact element={<ResetPassSuccess />} />
      <Route path="/*" element={<Navigate to="login" replace />} />
    </Routes>
  )
}
