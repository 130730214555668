import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {Button, TextField, Typography, Grid} from "@mui/material";

import Tinkoff from 'react-tinkoff-pay'

const AddInvoice = () => {
    const {t} = useTranslation()

    const {login} = useSelector(state => state.profileReducer)
 
    const [pay, setPay] = useState('')
    
    const handleClick = () => {
     let btn = document.getElementById("pay-field")
 
     btn.style.display = "block"
     if (pay !== '')  {
         console.log("PAY", pay)
         btn.style.display = "none"
         
         // Tinkoff.Link({	
         //     terminalkey: 'TinkoffBankTest',
         //     language: 'ru',
         //     amount: '513',
         //     order: '1488228',
         //     description: '',
         //     name: 'Георгий Алексеевич',
         //     email: 'JsusDev@yandex.ru',
         //     phone: '79055594564' 
         // }, link => {
         //     console.log(link) // => https://securepay.tinkoff.ru/xo7L8v
         // })
 
         setPay('')
     }
 }
 
    const handleChange = (e) => {
     setPay(e.target.value)
    }
  return (
    <Grid container style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Grid item style={{margin:"20px", width:"fit-content"}}>
                        <Typography style={{fontSize:"1.5rem"}}>Invoices</Typography>
                        <Typography>All recent invoices can be found below</Typography>
                    </Grid>
                    <Grid 
                        item 
                        style={{margin: "20px",
                         padding: "10px",
                         display: "flex",
                         justifyContent:"space-around", 
                         alignItems:"center"}}>
                        <div id="pay-field" style={{display: "none", margin:"0px", padding:"0px 5px"}} >
                            <TextField 
                                    value={pay}
                                    onChange={handleChange}
                                    id="outlined-helperText"
                                    label="Введите сумму"
                                    inputProps={{ style: { fontSize: "1rem" } }}
                                    InputLabelProps={{ style: { fontSize: "1rem" } }}
                                    >Введите сумму</TextField>
                        </div>
                        <Button 
                        onClick={handleClick} 
                        style={{margin:"10px", 
                        backgroundColor: "mediumpurple",
                        fontWeight: 600,
                        color: "white",
                        padding:"10px" }}
                        >+ Оплатить</Button>
                    </Grid>
                </Grid>
  )
}

export default AddInvoice