import { List, ListItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import TokenItem from './TokenItem';

const TokenList = ({tokens, handleDelete, handleRotate, handleChangeRights}) => {
  // console.log(tokens)
  useEffect(()=>{  console.log(tokens)
  }, [tokens])

  // const [changes, setChanges] = useState({})

  const [changedTokens, setChangedTokens] = useState(tokens)

  const changeFunc = (token) => {
    // const newTokens = tokens.map((value, index) => value.tokenid === e.tokenid)
    // if (newTokens.length > 1) setChangedTokens()
    setChangedTokens(...changedTokens, token)
  }
  if (!tokens) return <div><List></List></div>
  return (
    <div>
        <List>
          <ListItem style={{display:"flex", justifyContent:"space-between"}}>
                <Typography >Key name</Typography>
                <Typography>API Token</Typography>
          </ListItem>
          {tokens.map((item, index) => 
            <TokenItem 
              key={item.tokenid}
              handleDelete={handleDelete} 
              handleRotate={handleRotate}
              handleChangeRights={handleChangeRights}
              changeFunc={changeFunc}
              item={item}/>
            )}
        </List>
    </div>
  )
}

export default TokenList