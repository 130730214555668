'strict mode'
import React from 'react'
import AutoUtm from "./utmAuto"
import AutoHash from './hashAuto'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const utmParams = [
  'utm_source',
  'utm_campaign',
  'utm_term',
  'utm_medium',
  'utm_content',
  'utm_referrer'
]

const Autocomplit =  ({ link, domainId, setLink, setHash}) => {
  return (
    <Accordion sx={{ width: '100%' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      //   id="panel1a-header"
      >
        <Typography>utm parameters</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ wight: '100%'}}>
        <Grid container>
          {utmParams.map(element => {
            return (
            <Grid item key={element}>
              <AutoUtm setLink={setLink} link={link} domainId={domainId} utm={element} />
            </Grid>
            )
          })}
            <Grid item>
              <AutoHash setHash={setHash} link={link} domainId={domainId} utm="hash" />
            </Grid>
          </Grid>
        {/* <Typography>
        </Typography> */}
      </AccordionDetails>
    </Accordion>
  )
}

export default Autocomplit