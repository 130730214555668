import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"

const DialogDelete = ({openDeleteDialog, deleteDialogHandler,handleDelete, item, subject, title, action}) => {
  return (
    <Dialog open={openDeleteDialog} onClose={deleteDialogHandler}>
        <DialogTitle>{title} <b>{subject}</b></DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={deleteDialogHandler}>Cancel</Button>
            <Button onClick={()=>handleDelete(item)}>{action}</Button>
        </DialogActions>
    </Dialog>
  )
}

export default DialogDelete