import React, { useEffect, useState} from "react"
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  FormGroup,
  Typography,
  Link
} from '@mui/material'
import FormContainer from "../../components/Auth/FormContainer"
import PassInput from '../../components/Auth/PassInput'
import BootstrapInput from '../../components/Auth/BootstrapInput'
import BootstrapButton from '../../components/Auth/BootstrapButton'
import { useDispatch } from "react-redux"
import { signInAction } from "../../redux/actions"


export const AuthSignIn = () => {

  const dispatch = useDispatch()

  const [form, setForm] = useState({
    email: "",
    password: "",
    showPassword: false
  })
  const [valid, setValid] = useState({
    email: false,
    password: false
  })
  const [validForm, setValidForm] = useState(false)
  
  useEffect(() => {
    if (valid.email === true && valid.password === true) setValidForm(true)
    else setValidForm(false)
  }, [valid])

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
    if (event.target.name === 'email') {
      setValid({ ...valid, [event.target.name]: !!event.target.value.match(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}/) })
    }
    if (event.target.name === 'password') {
      setValid({ ...valid, [event.target.name]: event.target.value.length > 7 })
    }
  };

  const loginHandler = async () => {
    setValidForm(false)
    await dispatch(signInAction(form))
    setValidForm(true)
  };

  const pressHandler = async (event) => {
    if (event.key === "Enter" && valid.email === true && valid.password === true) {
      loginHandler();
    }
  };


  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormContainer>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <Box sx={{
          display: { xs: 'flow-root', md: 'flex', },
          marginBottom: 5
        }}>
          <Typography component="span" variant="h1" sx={{ flexGrow: 1 }}>Sign in</Typography>
          <Typography sx={{
            color: 'rgba(47, 50, 58, 0.4)',
            fontSize: '18px',
            lineHeight: '26px',
          }}>
            Don't have an account? <Link component={RouterLink} underline="hover" to="/register">Sign Up</Link>
          </Typography>
        </Box>
        <FormGroup xs={{ mb: 40, mt: 40 }}>
          <BootstrapInput
            fullWidth={true}
            required
            htmlFor="email"
            id="email"
            label="Email"
            error={false}
            value={form.email}
            margin="dense"
            onChange={changeHandler}
            onKeyPress={pressHandler}
            type="email"
            name="email"
            placeholder="Email"
            variant="outlined"
          />
          <PassInput
            onChange={changeHandler}
            onKeyPress={pressHandler}
            value={form.password}
            onClickIcon={handleClickShowPassword}
            onMousDownIcon={handleMouseDownPassword}
            showPassword={form.showPassword}
          />
        </FormGroup>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, justifyContent: 'space-between' }}>
          <BootstrapButton
            disabled={!validForm ? true : false}
            sx={{ mr: 20 }}
            variant="outlined"
            onClick={loginHandler}
          // endIcon={<LoginIcon />}
          >
            <Typography sx={{
              color: '#fff',
              letterSpacing: '0.02em',
              lineHeight: '26px',
              fontWeight: 500,
              fontSize: '18px',
            }}>Login</Typography>
          </BootstrapButton>
          <Typography align="right" variant="caption" display="block" gutterBottom sx={{
            fontSize: 18,
          }}>
            <Link component={RouterLink} underline="hover" to="/reset-pass">Forgot Password?</Link>
          </Typography>
        </Box>
      </Box>
    </FormContainer>
  )
};
