export function unique(arr) {
    let result = [];
  
    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }
    return result;
  }

export function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
  
    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
  
    var yy = date.getFullYear()
  
    return dd + '.' + mm + '.' + yy;
  }

export function getDashboardData(categories, series) {
  return {
    series: series,
    //   series: [
    //     {
    //     name: 'Links',
    //     data: [26, 85, 101, 98]
    //   }
    // ],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          height: 350,   
          fontFamily: 'Neucha, Arial, sans-serif',  
          zoom: {
            enabled: true
          }, 
          toolbar: {
            export: {
              csv: {
                filename: "1.svg",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "1.svg",
              },
              png: {
                filename: "1.svg",
              }}
          }
        },
        theme: {
          palette: 'palette2'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 2
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categories,
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                cssClass: 'apexcharts-xaxis-title',
            },
          },
          labels: {
            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
          // formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          //   return w.config.series[seriesIndex].name + ":  " + val
          // }
          },
          tickPlacement: 'between'

        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
        },
        title: {
          text: "TextY",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '20px',
              fontFamily: 'Neucha, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
          },

        },
      },
        // fill: {
        //   opacity: 1,
        //   colors:['#236CD9', '#F01D16', '#2DC22D']
        // },
        tooltip: {
            shared: true,
            intersect: false,
          y: {
            formatter: function (val) {
              return val + ""
            }
          },
          style: {
            fontSize: '10px',
            fontFamily: 'Neucha, Arial, sans-serif'
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        // noData: {
        //   text: "0",
        //   align: 'center',
        //   verticalAlign: 'middle',
        //   offsetX: 0,
        //   offsetY: 0,
        //   style: {
        //     color: "black",
        //     fontSize: '14px',
        //     fontFamily: undefined
        //   }
        // }
      },
    };
}