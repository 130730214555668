import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const BootstrapButton = styled(Button)(({ theme }) => {
  return {
    boxShadow: '0px 4px 33px 0px rgba(17, 198, 223, 0.5)',
    textTransform: 'none',
    padding: '6px 12px',
    border: 'none',
    borderRadius: '30px',
    width: '250px',
    height: '65px',
    backgroundColor: theme.palette.background.button.main,
  
    color: '#fff',
    letterSpacing: '0.02em',
    lineHeight: '26px',
    
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
  
    '&:hover': {
      backgroundColor: theme.palette.background.button.hover,
      border: 'none',
    },
    '&:active': {
      backgroundColor: '#0062cc',
      borderColor: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '&.Mui-disabled': {
      color: '#ddd',
      backgroundColor: theme.palette.background.button.disable
    }
  }
});

  export default BootstrapButton