import {PROFILE_CHANGE_DATA, PROFILE_CHANGE_ALL} from './types'

const initialState = {
    firstname: "",
    lastname: "",
    phone: "",
    login:"",
    blocked: false,
    language: "",
    error: {}
}

export const profileReducer = (state = initialState, {type, payload}) => {
  // console.log({type, payload})
  switch (type) {
    case PROFILE_CHANGE_DATA:
      return {
        ...state,
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
      }     
      case PROFILE_CHANGE_ALL: 
        return {
          ...state,
          firstname: payload.result.firstname,
          lastname: payload.result.lastname,
          phone: payload.result.phone,
          login: payload.result.login,
          language: payload.result.language,
          blocked: payload.result.blocked
        }

    default:
      return state;
  }  
}