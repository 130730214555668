import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Button, Grid } from '@mui/material';

export default function DatePickers({handlePickerPeriod, dateStart, dateEnd}) {
  const [valueStart, setValueStart] = React.useState(dateStart);
  const [valueEnd, setValueEnd] = React.useState(dateEnd);

  const handleChangeStart = (value) => {
        setValueStart(value)
    }
  const handleChangeEnd = (value) => {
        setValueEnd(value)
    }

  const handleClick = () => {
     handlePickerPeriod({from : valueStart, to: valueEnd})
  }

  // const handleCloseStart = () => {
  //     console.log(" const handleClose = () => {", value)
  //       handleChangeStart(value)
  //   }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container>
        <Grid item xs={10} md={10} sx={{display: "flex", justifyContent:"space-around"}}>
          <div style={{padding: "20px"}}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePickerStart"
              value={valueStart}
              onChange={handleChangeStart}
              // onClose={handleClose}
            />
          </div>
          <div style={{padding: "20px"}}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePickerEnd"
              value={valueEnd}
              onChange={handleChangeEnd}
              // onClose={handleClose}
            />
          </div>
        </Grid>
        <Grid item xs={2} md={2} style={{display:"flex", justifyContent: "center"}}>
          <Button onClick={handleClick}>ВЫБРАТЬ</Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
