import  { useState, useCallback, useEffect, useContext } from 'react';
import { Table,TableBody, Paper  ,TableCell ,Checkbox, TableContainer, TablePagination, TableRow} from "@mui/material";
import { makeStyles} from '@mui/styles';
import api from '../../redux/wsconnect'
import {Loader} from '../../components/Loader'
import {EnhancedTableHead} from './EnhancedTableHead'
import {EnhancedTableToolbar} from './EnhancedTableToolbar'

import { LinksContext } from '../../context/LinksContext';
import { useDispatch } from 'react-redux';
import { messageHandler } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export function LinksList() {
  const {links, setLinks, count, setCount, query} = useContext(LinksContext)
  const classes = useStyles()
  const dispatch = useDispatch()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('linkId')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [loading, setLoading] = useState(false)

  const fetchLinks = useCallback(async () => {
    try {
      const options = {
        limit: rowsPerPage,
        orderBy, 
        desc: order,
        offset: rowsPerPage * page,
        query: query
      }
      const {result: data, count} = await api('links', 'getLinks', options)       
      setLinks(data)
      setCount(Number(count))
    } catch (e) {
      console.log(e)
      dispatch(messageHandler("Server response error", "error"))
    }
    finally {
      setLoading(true)
    }
  }, [api, rowsPerPage, orderBy, order, page,query])

  useEffect(() => {
    fetchLinks()
  }, [fetchLinks, selected])

  if (!loading) {
    return <Loader/>
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = links.map((n) => n.linkId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={links.length}
            />
            <TableBody>
              {
              links
                .map((row) => {
                  const isItemSelected = isSelected(row.linkId);
                  const labelId = `enhanced-table-checkbox-${row.linkId}`;
                  let date = new Date(row.date).toLocaleString("en-US", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.linkId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.linkId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <p onClick={() => {navigator.clipboard.writeText(row.to)}}>{row.to}</p>
                        </TableCell>
                      <TableCell align="right">
                        <a rel="noopener noreferrer" target="_blank" href={`${row.from}`}>{row.from}</a>
                      </TableCell>
                      <TableCell align="right">
                        <p>{date}</p>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
