import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useRoutes } from './routes'
// import { useApi } from './hooks/api.hook'
import AuthContext from './context/AuthContext'
import { Loader } from './components/Loader'
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from './components/Navbar/Navbar'
import './app.css'
import { Container, CssBaseline } from '@mui/material'
import Theme from './Theme'

import {requestAccountInfo, changeAuthStatus, requestDomains} from './redux/actions'
import { useDispatch, useSelector } from "react-redux";

import {store} from './index'

import api from './redux/wsconnect'
import {ErrorHandler, MessageHandler} from './components/ErrorHandler'

api.metacom.on("login", (e)=> { // статус о том, что пользовател залогинен
  console.log("app.metacom.on()", e)
  store.dispatch(changeAuthStatus(e))
})

const CheckConnect = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { connected } = api.metacom

  const { error} = useSelector((state)=>state.authReducer) // данные из store

  useEffect(() => {
    console.log("connected", connected)
  }, [connected])

  useEffect(() => {
    window.meta = api.metacom
    api.metacom.on('error', (e) => {
      enqueueSnackbar("Connection lost, reconnecting...", { variant: 'warning' })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' })
  }, [error]) 

  return ''
}

function App() {
  const dispatch = useDispatch() 
  const {isMyLogin, error} = useSelector((state)=>state.authReducer) 
  const profileLogin = useSelector((state)=>state.profileReducer).login

  const routes = useRoutes(isMyLogin)

  useEffect(()=>{
    if(isMyLogin===true) {
      dispatch(requestAccountInfo())
      dispatch(requestDomains()) //ВКЛЮЧИТЬ?
    }
  },[isMyLogin])

  if (isMyLogin === null && profileLogin === "") {
    return <Loader />
  }

  return (
    <SnackbarProvider maxSnack={3} hideIconVariant={false} preventDuplicate >
      <MessageHandler/>
      <CssBaseline />
      <CheckConnect />
      {/* <AuthContext.Provider value={{
        isLogin: isMyLogin, api
      }}> */}
        <Theme>
          <Router>
            {isMyLogin && <Navbar> {routes} </Navbar>}

            {!isMyLogin && <Container maxWidth={"100%"} disableGutters={true} sx={{
              height: '100vh',
              background: "linear-gradient(135deg, rgba(249,254,255,1) 0%, rgba(148,228,255,1) 51%, rgba(166,246,229,1) 100%)",
              filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9feff', endColorstr='#a6f6e5', GradientType=1 )"
            }} >{routes}</Container>}
          </Router>
        </Theme>
      {/* </AuthContext.Provider> */}
    </SnackbarProvider>
  )
}

export default App
