import {
   Typography,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import BasicSelect from "../../../components/BasicSelect";

const parseDate = (date) => {
    let index = date.indexOf("T")
    return date.slice(0, index)
}

const limitChoose = [
    5, 10 ,15
]

const Sessions = ({sessions, platform, browser, handleSessionLimit}) => {


  return (
    <div style={{ padding: "20px" }}>
        <div style={{paddingLeft: "10px", paddingBottom: "10px" }}>
            <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>Session</Typography>
        </div>
        <Divider />
        <div style={{paddingTop: "10px"}}>
            <Typography component="span" style={{ fontWeight: 600, paddingLeft: "20px", fontSize: "1rem" }}>Current session: </Typography>
            <Typography component="span" style={{ fontWeight: 400, paddingLeft: "20px", fontSize: "1rem" }}> {platform && <span>{platform}</span>}, {browser} </Typography>
        </div>
        <div style={{ padding: "10px" }}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Typography component="span" style={{ fontWeight: 600, paddingLeft: "10px", fontSize: "1rem" }}>Previous sessions: </Typography>
                <BasicSelect label="limit" items={limitChoose} handleSessionLimit={handleSessionLimit}/>
            </div>
            <List>
                {
                    sessions.map(item=>
                        <ListItem key={item.sessionId}>
                            <div style={{ display:"flex", justifyContent:"space-between"}}>
                                <Typography component="span" style={{ fontWeight: 400, fontSize: "1rem", padding: "0 40px"}}>{ parseDate(item.date)}</Typography>
                                <Typography component="span" style={{ fontWeight: 400, fontSize: "1rem", padding: "0 40px"}}>{item.ip}</Typography>
                            </div>
                        </ListItem>)
                }
            </List>
        </div>
    </div>
  )
}

export default Sessions