import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import React from 'react'
import GetMessage from './GetMessage'

const SSL = (props) => {
    const {handleClick, loading, validTo, row} = props
  return (
      <>
        <LoadingButton
        onClick={handleClick}
        loading={loading}
        loadingIndicator="Checking..."
        variant="outlined"
        > Test SSL </LoadingButton>
        {validTo ? 
            <Typography variant="span">Valid to {validTo}</Typography> 
        : ""}
        <GetMessage message={row.message} status={row.status}></GetMessage>
  </>)
}

export default SSL