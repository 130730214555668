import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import {
   Container,
   Grid,
   Avatar,
   Typography, Paper, Divider, List, ListItem
} from "@mui/material";


export const Profile = () => {
   const {firstname, lastname, phone, login, blocked} = useSelector((state)=>state.profileReducer)

   const {t} = useTranslation()

   return (
      <>
         <Container style={{ paddingTop: 10 }}>
            <Grid
               container
               spacing={4}
               direction="row"
               justifyContent="center"
               alignItems="flex-start"
            >
               <Grid item xs={12} md={5}>
                  <Paper sx={{}} >
                     <div
                        style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
                     >
                        <Avatar sx={{ width: 50, height: 50, margin: 3 }}>{firstname[0]}{lastname[0]}</Avatar>
                        <Typography component="span" style={{ color: 'black', fontWeight: 500, fontSize: "1rem" }} variant="h7">
                           {firstname} {lastname}
                        </Typography>
                     </div>
                     <Divider />
                     <List style={{ paddingTop: 10, paddingBottom: 0, paddingLeft: 20, paddingRight: 20 }} >
                        <ListItem style={{ justifyContent: "space-between" }}>
                           <Typography component="span" style={{ fontWeight: 600,  fontSize: "1rem" }}>{t('email')}</Typography>
                           <Typography component="span" style={{  fontSize: "1rem" }}>{login}</Typography>
                        </ListItem>
                        <Divider />
                        <ListItem style={{ justifyContent: "space-between" }}>
                           <Typography component="span" style={{ fontWeight: 600,  fontSize: "1rem" }}>{t('phone')}</Typography>
                           <Typography component="span" style={{ fontSize: "1rem" }}>{phone}</Typography>
                        </ListItem>
                     </List>
                  </Paper>
               </Grid>
               <Grid item xs={12} md={7}>
                  <Paper>
                     <div style={{ padding: 20 }}>
                        <Typography component="span" variant="h7" color="textPrimary" style={{ fontWeight: 600, fontSize: "1rem" }}>
                           {t('personal_details')}
                        </Typography>
                     </div>
                     <Divider />
                     <List style={{ paddingTop: 15, paddingBottom: 10, paddingLeft: 20, paddingRight: 20 }} >
                        <ListItem style={{ justifyContent: "space-between" }}>
                           <Typography component="span" style={{ fontWeight: 600,  fontSize: "1rem" }}>{t('name')}</Typography>
                           <Typography component="span"style={{  fontSize: "1rem" }}>{firstname}</Typography>
                        </ListItem>
                        <ListItem style={{ justifyContent: "space-between" }}>
                           <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>{t('phone')}</Typography>
                           <Typography component="span" style={{ fontSize: "1rem" }}>{phone}</Typography>
                        </ListItem>
                        <ListItem style={{ justifyContent: "space-between" }}>
                           <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>{t('isAccBlocked')}</Typography>
                           {blocked && <Typography component="span" style={{ fontSize: "1rem" }}>{t("Yes")}</Typography>}
                        </ListItem>
                     </List>
                  </Paper>
               </Grid>
            </Grid>
         </Container>
      </>
   );
}
   
