import { AUTH_CHANGE_STATUS, MESSAGE_HANDLER } from './types'

const initialState = {
  isMyLogin: null,
  message: {value: {}, variant:""}
}

export const authReducer = (state = initialState, {type, payload}) => {
  // console.log("authReducer",{type, payload})
  switch (type) {
    case AUTH_CHANGE_STATUS:
      return {
        ...state,
        isMyLogin: payload
      }
    case MESSAGE_HANDLER:
      return {
        ...state,
        message: {value: payload.value, variant: payload.variant}
      }
    default:
      return state;
  }  
}