import { Typography } from '@mui/material'
import { useState, useEffect } from 'react'

export default function GetMessage({ message, status }) {
   const [color, setColor] = useState('black')

   useEffect(() => {
      switch (status) {
         case "error":
            setColor('red')
            break;
         case "success":
            setColor('green')
            break;
      }
   }, [status])

   return (
      <Typography sx={{ color }} variant="h6" gutterBottom component="div">
         {message}
      </Typography>
   )
}