import React, { useState, useEffect, useCallback } from 'react'
import { TextField } from '@mui/material'
import api from '../../redux/wsconnect'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// import { useThemeVariants } from '@mui/styles';
const filter = createFilterOptions();

export default function Asynchronous({link, utm, domainId, setLink}) {
  const [open, setOpen] = useState(false),
  [options, setOptions] = useState([]),
  loading = open && options.length === 0,
  [active, setActive] = useState(false),
  [url, setUrl] = useState(''),

  // запрос
  getUtm = useCallback( async () => {
    const data = await api("links", "utm", { utm, domainId })
    if (!data) return setActive(false)
    
    setOptions(data)
  }, [api, domainId, utm])

  useEffect(() => {
    if (!loading) return true
    if (active) return true
    setActive(true)
    getUtm()
  }, [loading, link, getUtm, active])



  // валидация
  useEffect(()=> {
    try {
      const url = new URL(link)
      setUrl(url.searchParams.get(utm) || '')
    } catch (error) {
    }
  }, [link])

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300, m: '1rem'}}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={url ?? ''}
      isOptionEqualToValue={(option, value) => option === value || null}
      getOptionLabel={(option) => '' + (option.inputValue || option || '')}
      onChange={(e, val)=> {
            setLink({name: utm, val: val?.inputValue || val})
      }}
      options={options || null}
      loading={loading}
      freeSolo
      renderOption={(props, option) => <li {...props}>{option.title || option}</li>}      
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const {inputValue} = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => {
          return inputValue === option || inputValue === option.inputValue
        })
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      renderInput={(params) => {
        return (<TextField {...params} label={utm} />)
      }}
    />
  );
}
