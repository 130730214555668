import React from 'react'
import {LinksList} from './LinksList'
import { LinksContextProvider } from '../../context/LinksContext'

export const LinksPage = () => {

  return (
    <LinksContextProvider>
      <LinksList/>
    </LinksContextProvider>
  )
}
