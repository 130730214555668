import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles"
import {
  Typography,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Collapse,
  Grid
    
} from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AddCircle as AddCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info
} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
import GetMessage from './GetMessage'
import { Loader } from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { messageHandler, requestDomains } from "../../redux/actions";
import Txt from "./Txt";
import SSL from "./SSL";
import DialogDelete from "../../components/DialogDelete";
import api from '../../redux/wsconnect'


const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
}));

const columns = [
  { id: 'baseUrl',
   label: 'Domain',
    minWidth: 170,
   //format: (value) => value.toLocaleString('en-US'), 
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'right',
    //   format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'last_check',
    label: 'last check',
    minWidth: 170,
    align: 'right',
    //   format: (value) => value.toFixed(2),
  }
];


const Row = (props) => {
  const { row, rows, handleDelete } = props
  const  [loading, setLoading] = React.useState(false)
  const  [open, setOpen] = React.useState(false)
  const  [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const  [validTo, setValidTo] = React.useState(false)

  const dispatch = useDispatch()
    
  const  handleClickSSL = async () => { // проверка SSL
      setLoading(true);
      const host = new URL(row.baseUrl)

      try {
        const result = await api('domain', 'checkSSL', { domainId: row.domainId, host: host.host }) 
        if (result.valid_to) setValidTo(result.valid_to)
      } catch (error) {
        dispatch(messageHandler("Server response error", "error"))
      }

      setLoading(false)
    }

  const  deleteDialogHandler = () => {
      setOpenDeleteDialog(!openDeleteDialog)
    }

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.domainId}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
              <TableCell key={column.id} align={column.align}>
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
          );
        })}
        <TableCell align="right">
          <IconButton onClick={deleteDialogHandler} disabled={!row.hasOwnProperty("txt")}><DeleteIcon/></IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: !open ? "none" : "1px solid rgb(224, 224, 224)" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  {
                  row.hasOwnProperty("txt") ? 
                    <Txt text={row.txt}/>
                  : 
                   <SSL handleClick={handleClickSSL} loading={loading} validTo={validTo} row={row}/>
                   }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DialogDelete openDeleteDialog={openDeleteDialog} deleteDialogHandler={deleteDialogHandler} handleDelete={handleDelete} title="Delete domain" action="Delete" item={row} subject={row.baseUrl}/>
    </>
  )
}

export const Domain = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  const {allDomains} = useSelector(state=>state.domainReducer)
  const dispatch = useDispatch()

  const  [addDomain, setAddDomain] = useState({  baseUrl: '' })
  const  [page, setPage] = useState(0)
  const  [rowsPerPage, setRowsPerPage] = useState(10)
  const  [rows, setRows] = useState(false)

  const  getDomains = useCallback(async () => {
      try {
        const domains = await api('domain', 'getAllDomain')
        // dispatch(requestDomains())
        setRows(domains.result)
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }
    }, [api])

    useEffect(() => {
      getDomains()
    }, [getDomains])

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const domainDialogHandler = () => {
      setOpenCreateDialog(!openCreateDialog)
    }

    const domainFieldHandlers = e => {
      setAddDomain({ [e.target.id]: e.target.value })
    }

    const handleChangeRowsPerPage = e => {
      setRowsPerPage(e.target.value)
      setPage(0)
    } 

    // const addDomainHandler = async e => { //добавление домена    //   //!! НОВЫЙ ПОДХОД
      // let baseUrl = ''
      // if (addDomain.baseUrl.indexOf('.') < 0){
      //   dispatch(messageHandler("No dot", "warning"))
      //   console.log("NO DOT")
      //   return false
      // }
      // try {
      //   if (addDomain.baseUrl.startsWith('https://')) {
      //     const url = new URL(addDomain.baseUrl)
      //     setAddDomain({ baseUrl: url.origin })
      //     baseUrl = url.origin
      //     console.log("addDomain.baseUrl.startsWith('https://')", baseUrl)
      //    }
      //   else if (addDomain.baseUrl.startsWith('http://')) {
      //     const url = new URL(addDomain.baseUrl)
      //     url.protocol = 'https:'
      //     setAddDomain({ baseUrl: url.origin })
      //     baseUrl = url.origin
      //     console.log("addDomain.baseUrl.startsWith('http://')", baseUrl)
      //   }
      //   else {
      //     const url = new URL('https://' + addDomain.baseUrl)
      //     setAddDomain({ baseUrl: url.origin })
      //     baseUrl = url.origin
      //     console.log("else",baseUrl)
      //   }
      // } catch (error) {
      //   console.log(error)
      //   dispatch(messageHandler("Invalid URL", "error"))
      //   return false
      // }
    // }

    const addDomainHandler = async e => { //добавление домена
        let baseUrl = ''
        try {
          const url = new URL(addDomain.baseUrl)
          setAddDomain({ baseUrl: url.origin })
          baseUrl = url.origin
        } catch (error) {
          dispatch(messageHandler("Invalid url", "warning"))
          try {
            const url = new URL('https://' + addDomain.baseUrl)
            setAddDomain({ baseUrl: url.origin })
            baseUrl = url.origin
            console.log(baseUrl)
          } catch (error) {
            dispatch(messageHandler("Invalid url", "warning"))
            return false
          }
        }
        if (baseUrl.indexOf('.') < 0){
          dispatch(messageHandler("No dot", "warning"))
          return false}
  
        try { 
          const res = await api('domain', 'add', { baseUrl })
          console.log(res)
          if (res?.success) {
                dispatch(messageHandler("domain added", "success"))
                setAddDomain({  baseUrl: '' })
                console.log("addDomain",addDomain)
                setOpenCreateDialog(false)
              }
              getDomains()
        } catch (error) {
          console.log(error)
          dispatch(messageHandler("Server response error", "error"))
        }  
      }
  
      const classes = useStyles()

    const handleDelete = async (row) => { // удаление одного домена
      setRows(rows.filter((item) => (item.domainId !== row.domainId)))
      try {
        const data = await api("domain", "deleteOneDomain", {domainId: row.domainId});
        if (data.success) dispatch(messageHandler("Domain was deleted", "success"))
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }
    }

    if (!rows) return <Loader />

  return (
    <>
      <main>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Domain Name settings
          </Typography>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <Row key={row.domainId} row={row} rows={rows} handleDelete={handleDelete} setRows={setRows} />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Button variant="contained" endIcon={<AddCircleIcon />} onClick={domainDialogHandler} >
            Add Domain
          </Button>
        </Paper>
      </main>
      <Dialog open={openCreateDialog} onClose={domainDialogHandler}>
        <DialogTitle>Add domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add DomainName to this account, please enter your DomainName name here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="baseUrl"
            label="domain Url"
            type="text"
            defaultValue={addDomain.baseUrl}
            fullWidth
            variant="standard"
            onChange={domainFieldHandlers}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={domainDialogHandler}>Cancel</Button>
          <Button onClick={addDomainHandler}>Add Domain</Button>
        </DialogActions>
      </Dialog>
    </>
  )

}
