import { InputBase } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
      '& .MuiInputBase-input': {
        marginBottom: theme.spacing(2),
        width: '100%',
        borderRadius: 40,
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        border: '1px solid rgba(231, 255, 250, 1)',
        boxShadow: '0px 4px 33px 0px rgba(25, 128, 111, 0.12)',
        padding: '11px 40px',
        // color: 'rgba(47, 50, 58, 0.4)',
        color: '#2F323A',
        height: theme.spacing(5),
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '26px',
    
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
          color: '#2F323A',
        },
      },
    }));

export default BootstrapInput