import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  ChevronRight as ChevronRightIcon,
  CallMissedOutgoing as CallMissedOutgoingIcon,
  ChevronLeft as ChevronLeftIcon,
  ViewList as ViewListIcon,
  Settings
  
} from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  CssBaseline,
  List,
  Toolbar,
  Drawer as MuiDrawer,
  Box
} from '@mui/material';
import { TopBar } from "../Header/TopBar";
import { NavLink as Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeaght: '3rem',
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function Navbar(props) {
  const [open, setOpen] = React.useState(true);
  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const {t} = useTranslation()
  return (
    <Box sx={{ display: 'flex', bgcolor: 'background.default' }}>
      <CssBaseline />
      <TopBar />
      <Drawer variant="permanent" open={open}>
        <Toolbar sx={{ mt: '3rem' }} />
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to="/dashboard" style={{color: "inherit", textDecoration: "inherit"}}>
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText>{t('dashboard')}</ListItemText>
            </ListItem>
          </Link>
          <Link to="/create" style={{color: "inherit", textDecoration: "inherit"}}>
            <ListItem button>
              <ListItemIcon>
                <CallMissedOutgoingIcon />
              </ListItemIcon>
              <ListItemText>{t('create')}</ListItemText>
            </ListItem>
          </Link>
          <Link to="/links" style={{color: "inherit", textDecoration: "inherit"}}>
            <ListItem button>
              <ListItemIcon>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText>{t("links")}</ListItemText>
            </ListItem>
          </Link>
          <Link to="/domain" style={{color: "inherit", textDecoration: "inherit"}}>
            <ListItem button>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText>{t('domains')}</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar sx={{ mt: '3rem' }} />
        {props.children}
      </Box>
    </Box>
  );
}
