import Box from '@mui/material/Box';

import allTimezonesJson from './allTimezones.json'
import { Autocomplete, TextField } from '@mui/material';

const allTimezones = allTimezonesJson.timezones

export default function SelectTimeZone() {
  return (
    <Box sx={{ minWidth: 120 }}>
       <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={allTimezones.map((option) => option[0] + ' ' + option[1])}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select time zone"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Box>
  );
}
