import React, { useState, useEffect, useCallback } from "react"
import api from '../../redux/wsconnect'
import { Loader } from '../../components/Loader'
import { useSnackbar } from 'notistack';
import { Box, FormGroup, Typography, Link } from '@mui/material';
import FormContainer from "../../components/Auth/FormContainer"
import BootstrapInput from '../../components/Auth/BootstrapInput'
import BootstrapButton from '../../components/Auth/BootstrapButton'

import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'

export const ResetPass = () => {
  const [valid, setValid] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")

  const changeHandler = (event) => {
    setEmail(event.target.value)
    setValid(!!event.target.value.match(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}/))
  };

  const { enqueueSnackbar } = useSnackbar();

  const pressHandler = async (event) => {
    if (event.key === "Enter" && valid === true) {
      resetPass();
    }
  };

  const resetPass = async () => {
    setValid(false)

    const data = await api("auth", "resetPass", { email })
    if (data instanceof Error) enqueueSnackbar(data.message, { variant: 'error' })
    else enqueueSnackbar(data.message, { variant: 'success' })

    setValid(true)
  }

  const validId = useCallback(async () => {
      const data = await api("auth", "emailCheck", id)
      if (data instanceof Error) {
        enqueueSnackbar(data.message, { variant: 'error' })
      } else {
        enqueueSnackbar(data.message, { variant: 'success' })
        navigate("/login");
        console.log('bla bla bla')
      }
      // navigate("/reset-pass");
  }, [api, id, enqueueSnackbar, navigate])

  useEffect(() => {
    if (id) validId()
  }, [validId, id])

  if (id) return <Loader />

  return (
    <FormContainer>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <Box sx={{
          display: { xs: 'flow-root', md: 'flex', },
          marginBottom: 5
        }}>
          <Typography variant="h1">Enter you email and we will send you a link to reset your password</Typography>
        </Box>
        <FormGroup xs={{ mb: 20 }}>
          <BootstrapInput 
            required
            htmlFor="email"
            id="email"
            label="Email Address"
            value={email}
            margin="dense"
            onChange={changeHandler}
            onKeyPress={pressHandler}
            type="email"
            name="email"
          />
        </FormGroup>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
          <BootstrapButton
              disabled={!valid}
              sx={{ mr: 20 }}
              variant="outlined"
              onClick={resetPass}
            >
              <Typography sx={{
                color: '#fff',
                letterSpacing: '0.02em',
                lineHeight: '26px',
                fontWeight: 500,
                fontSize: '18px',
              }}>Register</Typography>
          </BootstrapButton>
          <Typography align="right" variant="caption" display="block" gutterBottom sx={{
            fontSize: 18,
          }}>
          <Link component={RouterLink} underline="hover" to="/login">Sign In</Link>
          </Typography>
        </Box>
      </Box>
    </FormContainer>
  )
};
