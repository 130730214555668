import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectAutoWidth({label, items, handleSessionLimit}) {
  const [limit, setLimit] = React.useState(10);

  const handleChange = (event) => {
    handleSessionLimit(event.target.value)
    setLimit(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={limit}
          onChange={handleChange}
          autoWidth
          label={label}
        >
            {
                items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
            }
        </Select>
      </FormControl>
    </div>
  );
}
