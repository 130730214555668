import { useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect } from 'react';
import api from '../../../redux/wsconnect'

import {
   Container,
   Typography,
   Button, Paper, Divider,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
   DialogActions,
   TextField,
   Input,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { messageHandler } from '../../../redux/actions';
import TokenList from './TokenList';
import DialogDelete from '../../../components/DialogDelete';
import Sessions from './Sessions';
import { checkUserAgent } from '../../../lib/helpers';

export const Settings = () => {
      const {t} = useTranslation()
      const dispatch = useDispatch()
      const {platform, browser} = checkUserAgent()

      const [newToken, setNewToken]= useState('') //значение нового ключа
      const [newTokenName, setNewTokenName] = useState('') // название для нового ключа в диалоге
      const [allTokens, setAllTokens] = useState([]) 
      const [allSessions, setAllSessions] = useState([])

      const [rotateToken, setRotateToken] = useState({tokenName: '', token: ''})

      const [openCreateDialog, setOpenCreateDialog] = useState(false) // диалог создания
      const [openDeleteDialog, setOpenDeleteDialog] = useState(false) // диалог удаления
      const [openRotateDialog, setOpenRotateDialog] = useState(false) // диалог обновления


      const getTokens = useCallback(async () => { // получение всех токенов
         try {
           const data = await api("token", "showTokens", {});
           setAllTokens(data.result)
         } catch (e) {
            console.log(e)
            dispatch(messageHandler("Server response error", "error"))
         }
       }, [api])
     
       useEffect(() => {
         getTokens()
       }, [getTokens])


      const getSessions = useCallback(async (limitSessions = 10) => { // получение всех сессиий
         try {
           const data = await api("sessions", "getSessions", {limit: limitSessions});
           console.log(data)
           setAllSessions(data.result)
         } catch (e) {
            console.log(e)
            dispatch(messageHandler("Server response error", "error"))
         }
       }, [api])
     
      useEffect(() => {
         getSessions()
       }, [getSessions])

      const handleSessionLimit = (limit) => { // обработка выбора лимита выводимых сессиий
         getSessions(limit)
      }

      const handleDelete = async (token) => { // удаление одного токена
         setAllTokens(allTokens.filter((item) => (item.tokenid !== token.tokenid)))
         try {
            const data = await api("token", "deleteOneToken", {tokenid: token.tokenid});
            if (data.success) dispatch(messageHandler("Token was deleted", "success"))
         } catch (error) {
            console.log(error)
            dispatch(messageHandler("Server response error", "error"))
         }
      }

      const handleRotate = async (token) => { // обновление одного токена
         console.log("handleRotate")
         try {
            const data = await api("token", "rotateToken", {tokenid: token.tokenid});
            console.log("handleRotate",data, {tokenName: data.token_name, token: data.token})
            if (data.success) dispatch(messageHandler("Token was rotated", "success"))
            getTokens()
            setRotateToken({tokenName: data.result.token_name, token: data.result.token})
            console.log(rotateToken)
            handleRotateDialog()
         } catch (error) {
            console.log(error)
            dispatch(messageHandler("Server response error", "error"))
         }
      }

      const handleChangeRights = async (token, rights) => { // изменение настроек токена
         // console.log("handleChangeRights:    ", token,rights)
         try {
            const data = await api("token", "changeRights", {tokenid: token.tokenid, read: rights.read, write: rights.write, change: rights.change});
            if (data.success) dispatch(messageHandler("Token settings was changed", "success"))
         } catch (error) {
            console.log(error)
            dispatch(messageHandler("Server response error", "error"))
         }
      }

      const handleDeleteAllTokens = async () => { // удаление всех токенов
         
        // getTokens()
         setAllTokens([])
         try {
            const data = await api("token", "deleteTokens", {});
            if (data.success) dispatch(messageHandler("All tokens was deleted", "success"))
         } catch (error) {
            console.log(error)
            dispatch(messageHandler("Server response error", "error"))
         }
         setOpenDeleteDialog(!openDeleteDialog)
      }

       const deleteDialogHandler = () => { // диалог удаления всех токенов
         setOpenDeleteDialog(!openDeleteDialog)
      }

      const tokenDialogHandler = () => { // диалог создания токена
         setOpenCreateDialog(!openCreateDialog)
         setNewToken('')
         setNewTokenName('')
      }

      const tokenNameHandler = e => { // имя токена
         setNewTokenName(e.target.value)
      }

      const addTokenHandler = async () => { // создание токена
         if (newTokenName.length > 2) {
            try {
               const data = await api("token", "generateToken", {tokenName: newTokenName});
               console.log(data)
               getTokens()
               setNewToken(data.result.token)
               document.getElementById("token-text").style.display = "block"
               document.getElementById("add-token-btn").style.display = "none"
               document.getElementById("add-token-text").style.display = "none"
            } catch (error) {
               console.log(error)
               dispatch(messageHandler("Server response error", "error"))
            }
         }  
      } 


      const handleCopyTokenBtn = () => { // копирование токена
         navigator.clipboard.writeText(newToken)
         dispatch(messageHandler("Value was copied to the clipboard", "success"))
      }
      const handleCopyRotateTokenBtn = () => { // копирование обновленного токена
         navigator.clipboard.writeText(newToken)
         dispatch(messageHandler("Value was copied to the clipboard", "success"))
      }

      const handleRotateDialog = () => {
         setOpenRotateDialog(!openRotateDialog)
      }

   return (
         <Container style={{ paddingTop: 10 }}>
            <Paper>
               <div style={{ padding: "20px" }}>
                  <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>{t('settings')}</Typography>
               </div>
               <Divider />
               <div style={{ padding: "20px" }}>
                     <div style={{paddingLeft: "10px", paddingBottom: "10px", display:"flex", justifyContent:"space-between" }}>
                        <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>{t('integration')}</Typography>
                     </div>  
                     <Divider />
                     <Typography style={{ fontSize: "1rem" , padding: "5px 15px" }}>API keys are used for authorization, and must be part of every API call.</Typography>
                     <TokenList tokens={allTokens} handleDelete={handleDelete} handleRotate={handleRotate} handleChangeRights={handleChangeRights}/>
                     <div style={{paddingTop: "10px"}}>
                        <Button id='GEN_KEY' onClick={tokenDialogHandler} style={{ fontWeight: 600, fontSize: "1rem" }}>+Add key</Button>
                     </div>  
                     <div style={{paddingTop: "10px"}}>
                        <Button id='DELETE_ALL_TOKENS' onClick={async()=>{deleteDialogHandler()}} style={{ fontWeight: 600, fontSize: "1rem" }}>DELETE ALL TOKENS</Button>
                     </div>  
                  <Divider />
               </div>
               <Sessions sessions={allSessions} platform={platform} browser={browser} handleSessionLimit={handleSessionLimit}/>
            </Paper>
            <Dialog style={{minWidth: "50vw"}} open={openCreateDialog} onClose={tokenDialogHandler}>
               <div style={{display: "flex", justifyContent:"space-between"}}>
                  <DialogTitle>Add token</DialogTitle>
                  <CloseIcon onClick={tokenDialogHandler} style={{cursor:"pointer"}}/>
               </div>
               <DialogContent>
                  <div id="add-token-text">
                     <DialogContentText>
                        To add token, please enter name here.
                     </DialogContentText>
                     <TextField
                        autoFocus
                        margin="dense"
                        id="name-token"
                        label="name token"
                        type="text"
                        defaultValue={newTokenName}
                        fullWidth
                        variant="standard"
                        onChange={tokenNameHandler}
                     />
                  </div>
                  <div id="token-text" style={{display:"none", width: "50vw"}}>
                     <Typography variant='span' sx={{display:"flex", justifyContent:"space-between"}}>
                        {newTokenName}:
                        <Input value={newToken} sx={{flexGrow: 10 , margin: "0px 10px"}}/>
                        <ContentCopyIcon onClick={handleCopyTokenBtn} style={{cursor:"pointer"}}/>
                     </Typography>
                     <Typography sx={{padding:1}}>You will not be able to view this key again. Make sure to copy it before closing this window.</Typography>
                  </div>
               </DialogContent>
               <DialogActions>
                  <Button id = "add-token-btn" onClick={addTokenHandler}>Add token</Button>
               </DialogActions>
            </Dialog>


            <Dialog style={{minWidth: "50vw"}} open={openRotateDialog} onClose={handleRotateDialog}>
               <div style={{display: "flex", justifyContent:"space-between"}}>
                  <DialogTitle>Rotated token</DialogTitle>
                  <CloseIcon onClick={handleRotateDialog} style={{cursor:"pointer"}}/>
               </div>
               <DialogContent>
                  <div id="add-token-text">
                     {/* <DialogContentText>
                        Rotated token
                     </DialogContentText> */}
                     <Typography variant='span' sx={{display:"flex", justifyContent:"space-between"}}>
                        {rotateToken.tokenName}:
                        <Input value={rotateToken.token} sx={{flexGrow: 10 , margin: "0px 10px"}}/>
                        <ContentCopyIcon onClick={handleCopyRotateTokenBtn} style={{cursor:"pointer"}}/>
                     </Typography>
                     <Typography sx={{padding:1}}>You will not be able to view this key again. Make sure to copy it before closing this window.</Typography>
                  </div>
               </DialogContent>
            </Dialog>

            <DialogDelete openDeleteDialog={openDeleteDialog} deleteDialogHandler={deleteDialogHandler} handleDelete={handleDeleteAllTokens} title="Delete all tokens" item={""}/>

         </Container>
   );
}
