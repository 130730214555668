import { Grid, TextField } from '@mui/material'

const InputField = (props) => {
    const {label, value, handleChange} = props
  return (
          <Grid item xs={12}>
            <TextField
            //   required
              id="address1"
              name="link"
              label={label}
              fullWidth
              autoComplete="shipping address-line1"
              value={value || ''}
              onChange={handleChange}
            />
          </Grid>
  )
}

export default InputField