import {createContext} from 'react'

function noop() {}

export default createContext({
  token: null,
  userId: null,
  login: noop,
  logout: noop,
  isAuthenticated: false,
  api: noop,
  isLogin: null,
  restore: noop
})
