import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import  LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

import { createStore, compose, applyMiddleware } from "redux";
import { rootReducer } from "./redux/rootReducer";
import { Provider } from "react-redux";
import thunk from 'redux-thunk'

export const store = createStore(rootReducer,compose(
  applyMiddleware(
    thunk
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
  )
);


i18n.use(initReactI18next).use(LanguageDetector).use(HttpApi).init({
  supportedLngs: ['en', 'fr', 'ru', 'de'],
  fallbackLng:'en',
  detection: {
    order: ['cookie', 'localStorage', 'htmlTag','path','subdomain'],
    caches: ['cookie']
  },
  backend: {
    loadPath: '/assets/locales/{{lng}}/translation.json'
  },
  react: {
      useSuspense: false
  }
}) // нужный код

ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
