import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(date, amount, status) {
  return { date, amount, status };
}

const rows = [
  createData('01.01.2022', 1500, "in progress"),
  createData('02.01.2022', 1700, "draft"),
  createData('03.01.2022', 1200, "completed"),

];

export default function PaymentHistoryTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight:"600"}}>Date</TableCell>
            <TableCell sx={{fontWeight:"600"}} align="center">Amount</TableCell>
            <TableCell sx={{fontWeight:"600"}} align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.amount}</TableCell>
              {row.status === "completed" ? 
                <TableCell align="right">
                  <span style={{backgroundColor: "rgba(87, 202, 34, 0.1)",
                    color: "rgb(87, 202, 34)",
                    padding: "4.5px 9px",
                    borderRadius: "10px"
                    }}>
                      <b>{row.status}</b>
                  </span>
                </TableCell> :
                <TableCell align="right">
                  <span style={{backgroundColor: "rgba(140, 124, 240, 0.1)",
                    color: "rgb(140, 124, 240)",
                    padding: "4.5px 9px",
                    borderRadius: "10px"
                    }}>
                      <b>{row.status}</b>
                  </span>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
