import {  useSnackbar } from 'notistack';
import { useSelector } from "react-redux"
import React, { useEffect } from 'react'


export const MessageHandler = () => {
    const { enqueueSnackbar } = useSnackbar()
    const {message} = useSelector(state=>state.authReducer)
    useEffect(()=>{  
        console.log(message)
        if (message.variant) {
            if (message.value.status === "success") enqueueSnackbar("success", { variant: 'success' })
            else if (message.variant === "success") enqueueSnackbar(message.value, { variant: 'success' })
            else if (!message.value.message) enqueueSnackbar(message.value, { variant: 'warning' })
        else {
            enqueueSnackbar(message.value.message, { variant: message.variant })}
        } 
    }, [message])
    return ""
}
