import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import api from '../../redux/wsconnect'
import {Loader} from '../../components/Loader'
import {LinkCard} from './LinkCard'
import { useDispatch } from 'react-redux'
import { messageHandler } from '../../redux/actions'

export const DetailPage = () => {
  const [link, setLink] = useState(null)
  const [loading, setLoading] = useState(false)
  const linkId = useParams().id

  const dispatch = useDispatch()

  const getLink = useCallback(async () => {
    setLoading(false)
    try {
      const data = await api("links", "getLink", { linkId });
      setLink(data)
    } catch (e) {
      console.log(e)
      dispatch(messageHandler("Server response error", "error"))
    }
    finally { 
      setLoading(true) 
    }
  }, [linkId, api])

  useEffect(() => {
    getLink()
  }, [getLink])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      { !loading && link && <LinkCard link={link} /> }
    </>
  )
}
