import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from "react-i18next";
import i18next from 'i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux';
import api from '../../redux/wsconnect'

export default function LanguageFlags() {

    const languages = [
        {
          code: 'en',
          code_from_backend:"eng",
          country_code: 'gb',
          name: 'English'
        },
        {
          code: 'ru',
          code_from_backend:"rus",
          country_code: 'ru',
          name: 'Русский'
        }
      ]

    const { t } = useTranslation();

    const {language} = useSelector(state=>state.profileReducer);

    const currentLanguageCode = languages.find(lang => (lang.code_from_backend === language))?.code || 'en'

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    
    const [myLang, setMyLang] = useState(currentLanguageCode)

    const handleChangeLanguage = async (code) => {
        let newLang = languages.find(lang => (lang.code === code)).code_from_backend
        await api("profile", "changeLang", {newLang: newLang})
        i18next.changeLanguage(code)    
        setMyLang(code)    
    }

return (
<div className='flags' style={{position: "absolute", right: "0px", paddingRight: "105px"}}>
<Tooltip title={t('language')} arrow>
  <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
    <LanguageIcon style={{width:'32px', height:'32px'}}/>
  </IconButton>
</Tooltip>
<Menu 
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  onClick={handleClose}
  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  disableScrollLock={true}
  PaperProps={{
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1,
      bgcolor: '#F8F8F8',
      '& .MuiAvatar-root': {
        width: 20,
        height: 20,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: '#F8F8F8',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
        
      },
    },
  }}
>
  {languages.map(({ code, name}) => {
    return  (
        <Tooltip key={code} title={name} arrow placement='left'>
          <MenuItem  style={{
            backgroundColor: myLang === code ? '#D8D8D8' : '#F8F8F8',      
            border: myLang === code ? '2px solid #989898' : '#F8F8F8'             
          }}>
            <IconButton 
              className={classNames('dropdown-item')}
              onClick={() => {handleChangeLanguage(code)}}                            
            >
            <Typography component="span" style={{ fontFamily: "Neucha", fontSize: "20px" }}>{code}</Typography>
            </IconButton>  
          </MenuItem>    
        </Tooltip>                                   
      )})}
</Menu>
</div>
)
}