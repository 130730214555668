import {
   Container, Paper
} from "@mui/material";

import PaymentHistoryTable from './PaymentHistory';
import AddInvoice from './AddInvoice';

export const Payment = () => {
   return (
      <>
         <Container style={{ paddingTop: 10 }}>
            <Paper sx={{ }} >
               <AddInvoice/>
            </Paper>
            <Paper style={{marginTop:"20px"}}>
                <PaymentHistoryTable/>
            </Paper>
         </Container>
      </>
   );
}
   
