import {useState, useEffect}  from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Grid } from '@mui/material';

const ACCURACY = ["hour", "day","week", "month"]

const now = new Date()
now.toISOString()

const startToday = new Date() // начало дня
startToday.setHours(0, 0, 0, 0);
startToday.toISOString()

const dateStart = new Date('December 1, 2021 00:00:00') // начало исчисления
dateStart.toISOString()

const dateMonth = new Date()
dateMonth.setHours(dateMonth.getHours() - 24*30) // последний месяц
dateMonth.toISOString()

const dateWeek = new Date()
dateWeek.setHours(dateMonth.getHours() - 24*7) // последний месяц
dateWeek.toISOString()



const PERIOD = [
      {
        text : "This month",
        timePeriod: {
            from: dateMonth,
            to: now
          }
    },{
        text : "This week",
        timePeriod: {
            from: dateWeek,
            to: now
          }
    },{
        text : "Lifetime",
        timePeriod: {
            from: dateStart,
            to: now
        }
    },{
        text : "Today",
        timePeriod: {
            from: startToday,
            to: now
        }
    }
]

export default function SelectPeriod({handleChangeParametres, allDomains=[]}) {
  // const filteredDomains = allDomains.filter(name => name !== null) // убрать null

  const [period, setPeriod] = useState(PERIOD[0].timePeriod)
  const [accuracy, setAccuracy] = useState("day")
  const [domain, setDomain] = useState("") //?? КАКОЙ ПО ДЕФОЛТУ?

  useEffect(()=> {
    let filteredDomains = allDomains.filter(name => name !== null) // убрать null
    setDomain(filteredDomains[0])
  },[])
  console.log(domain)


  const handleChangePeriod = (event) => {
    let period = PERIOD.find(item => item.text === event.target.value).timePeriod
    setPeriod(period);
  };

  const handleChangeAccuracy = (event) => {
    setAccuracy(event.target.value);
  };

  const handleChangeDomain = (event) => {
    setDomain(event.target.value);
  };

  const handleClick = () => {
    console.log("handleClick")
    handleChangeParametres(period, accuracy, domain)
  }


  return (
      <Grid container>
        <Grid item xs={10} md={10}>
          <div style={{padding: "20px", width: "auto"}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Period</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={PERIOD.find(item => item.timePeriod === period)?.text || ""}
                label="Period"
                onChange={handleChangePeriod}
              >
                  {
                      PERIOD.map((item, index) => <MenuItem key={item.text} value={item.text}>{item.text}</MenuItem>)
                  }
              </Select>
            </FormControl>
          </div>
          <div style={{padding: "20px", width: "auto"}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Accuracy</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accuracy || ""}
                label="Accuracy"
                onChange={handleChangeAccuracy}
              >
                  {
                      ACCURACY.map((item, index) => <MenuItem key={item} value={item}>{item}</MenuItem>)
                  }
              </Select>
            </FormControl>
          </div>
          <div style={{padding: "20px", width: "auto"}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Domain</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={domain || ""}
                label="Domain"
                onChange={handleChangeDomain}
              >
                  {
                      allDomains.map((item, index) => <MenuItem key={item} value={item}>{item}</MenuItem>)
                  }
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={2} md={2} style={{display:"flex", justifyContent: "center"}}>
            <Button onClick={handleClick}>ВЫБРАТЬ</Button>
        </Grid>     
      </Grid>
  );
}
