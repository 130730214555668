import ReactApexChart from "react-apexcharts";
import SelectPeriod from './SelectPeriod'
import { useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect } from 'react';
import api from '../../redux/wsconnect'
import {unique, formatDate, getDashboardData} from './helpers'
import SelectDomain from "./SelectDomain";
import SelectAccuracy from "./SelectAccuracy";
import { useDispatch, useSelector } from "react-redux";
import { messageHandler, requestDomains } from "../../redux/actions";
import DatePickers from "./DatePickers";

const testDateStart = new Date()
testDateStart.setHours(testDateStart.getHours() - 30 * 24)
testDateStart.toISOString()

const testDateEnd = new Date()
testDateEnd.setHours(testDateEnd.getHours() - 24)
testDateEnd.toISOString()

const now = new Date()
now.toISOString()


export default function Chart() {
  const dispatch = useDispatch()
  const {allDomains} = useSelector(state=>state.domainReducer)
  // console.log("CHART_____START_DOMAINS",allDomains)
  const [chartData, setChartData] = useState([])

  const [searchPeriod, setSearchPeriod] = useState({from: testDateStart, to: now}) // период поиска, изначально: lifetime
  const [searchDomain, setSearchDomain] = useState("") // домен для поиска
  const [searchAccuracy, setSearchAccuracy] = useState("") // точность поиска


  const handleChangeParametres = (period, accuracy, domain) => { // параметры для поиска
    console.log(period, accuracy, domain)
    setSearchPeriod(period);
    setSearchDomain(domain)
    setSearchAccuracy(accuracy)
    getData(period, domain)
  };

  const handlePickerPeriod = (date) => { 
    setSearchPeriod(date)
    getData(date)
  };


  const getData = useCallback(async (period = {from: testDateStart, to: now}, domain = undefined) => { // получение данных для дашборда
    dispatch(requestDomains())
    console.log("CHART___GETDATA_DOMAINS", allDomains)
    try {
      console.log("ЗАПРОС:  ", period.from,"TO: ", period.to, domain)
      const data = await api("dashboard", "newInfo", { dateStart: period.from, dateEnd: period.to, dateDeltaStr: "day",  domain: domain });
      console.log("ОТВЕТ:  ", data)
      setChartData(data.result)
      // const data1 = await api("dashboard", "newInfo", { dateStart: period.from, dateEnd: period.to, dateDeltaStr: "hour", dateDeltaInt: 1 });
      // console.log("______________________-",data1)
    } catch (e) {
      console.log(e)
      dispatch(messageHandler("Server response error", "error"))
    }
  }, [api])

  useEffect(() => {
    getData()
  }, [getData])




  const uniqueDates = unique(chartData.map((item)=>item.date)) // заменить на chartDataLinks
  const uniqueNames = allDomains
    .filter(domain=>(domain?.status === "online")) // только те, что online
    .map(domain=>domain.baseUrl) 


  // console.log("chartDataClicks:______", chartDataClicks)
  // console.log("uniqueDates:____",uniqueDates)
  // console.log("uniqueNames:_______",uniqueNames)
 
  const filteredUniqueNames = uniqueNames.filter(name => name !== null) // убрать null
  const filteredUniqueDates = uniqueDates.map(date => { // выделить только даты
    let index = date.indexOf("T")
    return date.slice(0, index)
  })

  const mySeries = uniqueNames.map(name => {
    let data = uniqueDates.map(date => {
      let x = chartData.find(item => (item.date === date && item.domain === name))
      return x?.value || 0
      })
    return {name: name, data: data}
  })

  console.log(mySeries)

  const dashboardData = getDashboardData(filteredUniqueDates, mySeries) // все настройки дашборда (по X, по Y)

    return (
      <div id="chart" style={{padding: "10px"}}>
        <ReactApexChart options={dashboardData.options} series={dashboardData.series} type="bar" height={350} />
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <DatePickers handlePickerPeriod={handlePickerPeriod} dateStart={searchPeriod.from} dateEnd={searchPeriod.to}></DatePickers>
        </div>
        {/* <SelectPeriod handleChangeParametres={handleChangeParametres} allDomains={uniqueNames}/> */}
        {/* <SelectDomain allDomains={uniqueNames} handleChangeDomain={handleChangeDomain}/>
        <SelectAccuracy handleChangeAccuracy={handleChangeAccuracy}/> */}
      </div>
 );
}
