import {
   Container,
   Grid,
   Box,
   Typography,
   TextField,
   Button, Paper, Divider
} from "@mui/material";

import { useTranslation } from 'react-i18next';

import { useState} from "react";
import api from '../../redux/wsconnect'
import { useDispatch } from "react-redux";
import { messageHandler } from "../../redux/actions";

export const ChangePassword = () => {
   const {t} = useTranslation()

   const dispatch = useDispatch()

   const [pass, setPass] = useState({oldPassword: '',newPassword: '', checkNewPassword: '' })
   const onOldPass = (value) => setPass({...pass, oldPassword: value})
   const onNewPass = (value) => setPass({...pass, newPassword: value})
   const onConfirmPass = (value) => setPass({...pass, checkNewPassword: value})

   // const pressHandler = async (event) => {
   //    console.log("PRESS")
   //    if (event.key === "Enter") {
   //       handleClickDone();
   //    }
   //  };

   const handleClickDone = async () => {
      try {
         const res = await api('profile', 'updatePassword',{oldPassword: pass.oldPassword, newPassword: pass.newPassword, checkNewPassword: pass.checkNewPassword})
         if (res.success) dispatch(messageHandler("Password has been changed", "success"))
         else dispatch(messageHandler(res, "warning"))
      } catch (error) {
         console.log(error)
         dispatch(messageHandler("Server response error", "error"))
      }
   }

   const handleClickReset = () => {
      setPass({oldPassword: '',newPassword: '', checkNewPassword: '' })
   }

   return (
      <>
         <Container style={{ paddingTop: 10 }}>
            <Paper sx={{
               ':hover': {
                  boxShadow: 5,
               },
            }}>
               <div style={{ padding: 20 }}>
                  <Typography component="span" style={{ fontWeight: 600, fontSize: "1rem" }}>{t('change_pass')}</Typography>
               </div>
               <Divider />
               <Box
                  component="form"
                  sx={{
                     "& .MuiTextField-root": { m: 2.5, width: "40ch" }
                  }}
                  noValidate
                  autoComplete="off"
               >
                  <div style={{ paddingTop: 20, paddingLeft: 20 }}>
                     <TextField
                        value={pass.oldPassword}
                        id="outlined-helperText"
                        label={t('current_pass')}
                        inputProps={{ style: { fontSize: "1rem" } }}
                        InputLabelProps={{ style: { fontSize: "1rem", paddingTop:"1px" } }}
                        onChange={(e) => { onOldPass(e.target.value)  }}
                     />
                  </div>
                  <div style={{ paddingLeft: 20, paddingBottom: 20, paddingTop: 20, justifyContent: "space-between" }}>
                     <Grid
                        container
                        spacing={4}
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                     ></Grid>
                     <TextField
                        value={pass.newPassword}
                        id="outlined-helperText"
                        label={t('new_pass')}
                        inputProps={{ style: {fontSize: "1rem" } }}
                        InputLabelProps={{ style: { fontSize: "1rem",paddingTop:"1px" } }}
                        onChange={(e) => { onNewPass(e.target.value)  }}
                     />
                     <TextField
                        value={pass.checkNewPassword}
                        id="outlined-helperText"
                        label={t('confirm_pass')}
                        inputProps={{ style: { fontSize: "1rem" } }}
                        InputLabelProps={{ style: { fontSize: "1rem",paddingTop:"1px" } }}
                        onChange={(e) => { onConfirmPass(e.target.value)  }}
                     />
                  </div>
               </Box>
               <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20, paddingBottom: 10 }}>
                  <Button onClick={handleClickDone} style={{ fontWeight: 600, fontSize: "1rem" }}>{t('change_pass')}</Button>
                  <Button onClick={handleClickReset} style={{ fontWeight: 600, fontSize: "1rem" }}>{t('clear')}</Button>
               </div>
            </Paper>
         </Container>
      </>
   );
}
