import { Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import {useState} from 'react'

const TxtPopover = ({text}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
  return (
    <Typography style={{display:"flex", verticalAlign:"center"}} >TXT: {text}<InfoIcon onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}/>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        > 
          <Typography sx={{ p: 1 }}>TXT: txt запись длиной в 30 символов</Typography>
        </Popover>
    </Typography> 
  )
}

export default TxtPopover