import React from 'react'
import Chart from './Chart'
import './dashboard.css'

const DashboardPage = () => {
  return (
    <div className='dashboard' style={{padding: "10px"}}>
        <Chart/>
    </div>
  )
}

export default DashboardPage