const Search = (props) => { // можно переиспользовать
  return (
     <input
        type="text"
        placeholder={props.placeholder}
        value={props.searchValue}
        onChange={props.handleChangeSearch}
        style={{width:" 70%"}}
      />
  )
}

export default Search