import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import {
   Container,
   Grid,
   Avatar,
   Box,
   Typography,
   TextField,
   Button, Paper, Divider
} from "@mui/material";
import { useState } from 'react';
import { changeProfileData } from '../../redux/actions';
import SelectTimeZone from './SelectTimeZone';

export const PersonalDetails = () => {

      const {firstname, lastname, phone} = useSelector((state)=>state.profileReducer) // данные из store

      const dispatch = useDispatch() // диспетчеры
      const onChangeFirstName = (value) => setProfile({...profile, firstname: value})
      const onChangeLastName = (value) => setProfile({...profile, lastname: value})
      const onChangePhone = (value) => setProfile({...profile, phone: value})
      const handleClick = () => {
         dispatch(changeProfileData(profile))
      }

      const {t} = useTranslation()

      const [profile, setProfile] = useState({firstname, lastname, phone})
   return (
      <>
         <Container style={{ paddingTop: 10 }}>
            <Grid
               container
               spacing={5}
               direction="row"
               justifyContent="center"
               alignItems="flex-start"
            >
               <Grid item xs={12} md={4}>
                  <Paper sx={{
                     ':hover': {
                        boxShadow: 5,
                     },
                  }}>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           justifyContent: "center",
                           height: "auto",
                           padding: 20
                        }}
                     >
                        <Avatar sx={{ width: 120, height: 120 }}>{firstname[0]}{lastname[0]}</Avatar>
                        <Button style={{ marginTop: 6, fontWeight: 600, fontSize: "1rem" }}>{t('upload')}</Button>
                     </div>
                  </Paper>
               </Grid>
               <Grid item xs={12} md={8}>
                  <Paper sx={{
                     ':hover': {
                        boxShadow: 5,
                     },
                  }}>
                     <div style={{ padding: 20 }}>
                        <Typography component='span' variant="h7" color="textPrimary" style={{ fontWeight: 600, fontSize: "1rem" }}>
                           {t('personal_details')}
                        </Typography>
                     </div>
                     <Divider />
                     <Box
                        component="form"
                        sx={{
                           "& .MuiTextField-root": { marginLeft: 4.5, marginTop: 2, marginBottom: 1, width: "36ch" }
                        }}
                        noValidate
                        autoComplete="off"

                     >
                        <div style={{ paddingTop: 30, flexDirection: "column", display: "flex", alignItems: "center" }}>
                           <TextField
                              value={profile.firstname}
                              onChange={(e) => { onChangeFirstName(e.target.value)  }}
                              id="outlined-helperText"
                              label={t('first_name')}
                              inputProps={{ style: { fontSize: "1rem" } }}
                              InputLabelProps={{ style: {fontSize: "1rem" } }}
                           />
                           <TextField
                              value={profile.lastname}
                              onChange={(e) => { onChangeLastName(e.target.value) }}
                              id="outlined-helperText"
                              label={t('last_name')}
                              inputProps={{ style: { fontSize: "1rem" } }}
                              InputLabelProps={{ style: { fontSize: "1rem" } }}
                           />
                           <TextField
                              value={profile.phone}
                              onChange={(e) => { onChangePhone(e.target.value) }}
                              id="outlined-helperText"
                              label={t('phone')}
                              inputProps={{ style: { fontSize: "1rem" } }}
                              InputLabelProps={{ style: { fontSize: "1rem" } }}
                           />

                        </div>
                     </Box>
                     <div style={{ paddingBottom: 4, display: "flex", justifyContent: "center"}}>
                        <Button onClick={handleClick} style={{ fontWeight: 600, fontSize: "1rem" }}>{t('save')}</Button>
                     </div>
                     <div style={{ padding: "20px" }}>
                        <Divider />
                        <div style={{ padding: "20px" }}>
                           <Typography component='span' variant="h7" color="textPrimary" style={{ fontWeight: 600, fontSize: "1rem" }}>
                           Time zone
                        </Typography>
                        </div>
                        <SelectTimeZone/>
                     </div>
                  </Paper>
               </Grid>
            </Grid>
         </Container>
      </>
   );
}


 
