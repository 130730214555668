import { Button, ListItem, Switch, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import {useState} from 'react'
import DialogDelete from '../../../components/DialogDelete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import api from '../../../redux/wsconnect'
import { messageHandler } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const TokenItem = ({item, handleDelete = Function.prototype, handleRotate = Function.prototype, handleChangeRights = Function.prototype}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [showDeleteIcon, setShowDeleteIcon] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openRotateDialog, setOpenRotateDialog] = useState(false)

    const [read, setRead] = useState(item.read)
    const [write, setWrite] = useState(item.write)
    const [change, setChange] = useState(item.change)

    const [visibleBtnSave, setVisibleBtnSave] = useState(true)


    const deleteDialogHandler = () => {
      setOpenDeleteDialog(!openDeleteDialog)
    }
    const rotateDialogHandler = () => {
      console.log("rotateDialogHandler")
      // setOpenRotateDialog(!openRotateDialog)
      handleRotate(item)
    }

    const handleClickSave = () => {
          handleChangeRights(item ,{read: read, write: write, change: change})
    }

  return (
    <ListItem 
        style={{display:"flex", justifyContent:"space-between"}} 
        onMouseEnter={() => setShowDeleteIcon(true)} 
        onMouseLeave={() => setShowDeleteIcon(false)}>
              <Typography sx={{width: "7vw"}}>{item.token_name}</Typography>
              <div style={{paddingTop: "10px"}}>
                <Button disabled={visibleBtnSave} onClick={handleClickSave} sx={{ opacity: showDeleteIcon  ? '1' : '0', cursor:"pointer", fontWeight: 600, fontSize: "0.7rem"}}>SAVE</Button>
              </div> 
              <div>
                <Switch checked={read} onChange={(e) => {
                  setRead(e.target.checked)
                  setVisibleBtnSave(false)
                }} {...label} />
                <Typography component="span" style={{ fontWeight: 400, fontSize: "0.8rem" }}>read</Typography>
              </div>
              <div>
                <Switch checked={write} onChange={(e) => {
                  setWrite(e.target.checked)
                  setVisibleBtnSave(false)
                }} {...label}  />
                <Typography component="span" style={{ fontWeight: 400, fontSize: "0.8rem" }}>write</Typography>
              </div>
              <div>
                <Switch checked={change} onChange={(e) => {
                  setChange(e.target.checked)
                  setVisibleBtnSave(false)
                }} {...label}  />
                <Typography component="span" style={{ fontWeight: 400, fontSize: "0.8rem" }}>change</Typography>
              </div> 
              <div style={{display:"flex"}}>
                <Typography>{item.token}</Typography>
                <ChangeCircleIcon onClick={rotateDialogHandler} sx={{ opacity: showDeleteIcon  ? '1' : '0', cursor:"pointer", margin: "0 5px"}} />
                <DeleteIcon onClick={deleteDialogHandler} sx={{ opacity: showDeleteIcon  ? '1' : '0', cursor:"pointer", margin: "0 5px"}}/>
              </div>
              <DialogDelete openDeleteDialog={openDeleteDialog} deleteDialogHandler={deleteDialogHandler} handleDelete={handleDelete} title="Delete API Key" action="DELETE" item={item} subject={item.token_name}/>
              {/* <DialogDelete openDeleteDialog={openRotateDialog} deleteDialogHandler={rotateDialogHandler} handleDelete={handleRotate} title="Rotate API Key" action="ROTATE" item={item} subject={item.token_name}/> */}
    </ListItem>
  )
}

export default TokenItem