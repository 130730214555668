import React from "react"
import BootstrapInput from './BootstrapInput'

import {
    VisibilityOutlined,
    VisibilityOffOutlined
  } from '@mui/icons-material';

import {
    InputAdornment,
    IconButton
  } from '@mui/material'

const PassInput = (props) => (
    <BootstrapInput
        type={props.showPassword ? "text" : "password"}
        id="password"
        htmlFor="password"
        label="Password"
        required
        margin="dense"
        placeholder="********"
        name="password"
        value={props.value}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        endAdornment={
        <InputAdornment position="start" sx={{
            position: 'absolute',
            right: '30px',
            top: '33px'
        }}>
            <IconButton
            aria-label="toggle password visibility"
            onClick={props.onClickIcon}
            onMouseDown={props.handleMouseDownPassword}
            edge="end"
            >
            {props.showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
        </InputAdornment>
        }
    />
)

export default PassInput