import * as React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Typography, Tab, Box} from '@mui/material';
import { useTranslation } from 'react-i18next';
import  {Profile}  from "./Profile"
import {PersonalDetails} from "./PersonalDetails"
import { ChangePassword } from "./ChangePassword"
import { Settings } from "./Settings/Settings"

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (

            <Box sx={{ p: 3 }}>
               <Typography component="span">{children}</Typography>
            </Box>

         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

export default function ProfileNavBar() {
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   const {t} = useTranslation()

   return (
      <Box sx={{ width: '100%' }}>
         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label={t('profile')} {...a11yProps(0)} />
               <Tab label={t('personal_details')} {...a11yProps(1)} />
               <Tab label={t('change_pass')} {...a11yProps(2)} />
               <Tab label={t('settings')} {...a11yProps(3)} />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <Profile />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <PersonalDetails />
         </TabPanel>
         <TabPanel value={value} index={2}>
            <ChangePassword />
         </TabPanel>
         <TabPanel value={value} index={3}>
            <Settings />
         </TabPanel>
      </Box>
   );
}
