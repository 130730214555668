
 const SET_LINKS_DATA = "SET_LINKS_DATA"
 const SET_COUNT = "SET_COUNT"
 const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"
 
 export const reducer = (state, { type, payload }) => {
    switch (type) {
       case SET_LINKS_DATA:
          return {
             ...state,
             links: payload
          }
       case SET_COUNT:
          return {
             ...state,
             count: payload
          }
       case SET_SEARCH_VALUE:
          return {
             ...state,
             linkSearchValue: payload
          }
       default:
          return state;
    }
 }