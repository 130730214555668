import { checkUserAgent } from '../lib/helpers'
import { PROFILE_CHANGE_DATA, PROFILE_CHANGE_ALL, AUTH_CHANGE_STATUS, MESSAGE_HANDLER, DOMAINS_CHANGE_ALL } from './types'

import api from './wsconnect'


  export const changeProfileData = (value) => { // изменения данных в бэкенде и в store
    return async dispatch => {
      try {
        const res = await api("profile", "updateAccountInfo", {firstname: value.firstname, lastname: value.lastname, phone: value.phone + ""})
        console.log(res)
        if (res.success) {
          dispatch(messageHandler("Profile data has been changed", "success"))
          dispatch(changeProfileDataStore(value))
        }
        else if (res.message.includes("Invalid parameters")) dispatch(messageHandler(res.message, "warning"))
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }
       
    }
  }
  export const changeProfileDataStore = (value) => {  // изменения данных в store
    return {
      type: PROFILE_CHANGE_DATA,
      payload: value
    }
  }

  export const changeAll = (value) => { // все данные профиля
      return {
        type: PROFILE_CHANGE_ALL,
        payload: value
      }
    }
    export const changeAuthStatus = (value) => { // изменения статуса логина
            return {  
        type: AUTH_CHANGE_STATUS,
        payload: value
      }
    }
    
    export const messageHandler = (value, variant) => { // обработчик ошибок
            return {
        type: MESSAGE_HANDLER,
        payload: {value, variant}
      }
    }

export const requestAccountInfo = () => { // запрос всех данных профиля при входе в сервис
    return async dispatch => {
      try {
        const res = await api('profile', 'getAccountInfo',{})
        dispatch(changeAll(res))
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }   
    }
  }

export const signInAction = ({email, password}) => { 
    return async dispatch => {
      try {
        const {platform, browser} = checkUserAgent()
        const signIn = await api("auth", "signin", { email, password, platform, browser})
        if (signIn?.status === 'logged'){
          dispatch(changeAuthStatus(signIn?.status === 'logged'))
          localStorage.setItem('metarhia.session.token', signIn.token)
        }
        else dispatch(messageHandler(signIn, "error"))
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }
    
    }
  }

export const signOutAction = () => {
    return async dispatch => {
      try {
        await api("auth", "logout")
        api.metacom.socket.login =false 
        dispatch(changeAuthStatus(false))
        localStorage.removeItem('metarhia.session.token')
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }
    }
  }
  

export const changeAllDomains = (value) => { // все домены профиля
    return {
      type: DOMAINS_CHANGE_ALL,
      payload: value
    }
  }

export const requestDomains = () => { // запрос всех доменов профиля при входе в сервис
    return async dispatch => {
      try {
        const res = await api('domain', 'getAllDomain')
        dispatch(changeAllDomains(res.result))
      } catch (error) {
        console.log(error)
        dispatch(messageHandler("Server response error", "error"))
      }   
    }
  }