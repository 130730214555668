import React, { useState, useEffect, useCallback } from "react"
import api from './../../redux/wsconnect'
import { useNavigate } from "react-router-dom"
import Autocomplete from '../../components/Autocomplete'
import {
  Typography,
  Paper,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Loader } from '../../components/Loader'
import { useTranslation } from 'react-i18next';
import InputField from "../../components/InputField";
import { useDispatch } from "react-redux";
import { messageHandler } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "autos",
      marginRight: "auto",
    },
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export const CreatePage = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const classes = useStyles()
  const  [domain, setDomain] = useState(null)
  const  navigate = useNavigate()
  const  [link, setLink] = useState('')
  const  [params, setParams] = useState(new Set())
  const  [selectDomain, setSelectDomain] = useState({})

  const  getDomains = useCallback(async () => {
    try {
      const domains = await api('domain', 'getDomain')
      setDomain(domains.result)
    } catch (error) {
      console.log(error)
      dispatch(messageHandler("Server response error", "error"))
    }
  }, [api])

  useEffect(() => {
    getDomains()
  }, [getDomains])

  useEffect(() => {
    try {
      link.searchParams.forEach((val, key) => {
        setParams(params.add([key, val]))
      })
    } catch (error) {
      
    }
  }, [link, params])

  const pressHandler = async event => {
    try {
      const res = await api("links", "setLink", { from: link, to: selectDomain.baseUrl });
      console.log(res)
      if (res?.status === "success") dispatch(messageHandler("link created", "success"))
      else if (!res?.status) dispatch(messageHandler("Is not link", "warning"))
      console.log(res)
      //navigate(`/detail/${res.links[0].linkId._id}`); //!!
      setLink('')
    } catch (e) {

    }
  };

  const handleChangeInput = (e) => {
    try {
      setLink(e.target.value)
    } catch (error) {
    }
  }

  const selectHandler = e => {
    setSelectDomain(domain.filter(dom => dom.hostname === e.target.value)[0])
  }

  useEffect(() => {
    if (domain !== null && domain.length > 0) setSelectDomain(domain[0])
  }, [domain])

  if (domain === null || domain === undefined) return <Loader />
  if (domain.length > 0) {
    return (
    <main>
      <Paper elevation={3} className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
         { t('create_short_link')}
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel id="domain-input">{t('domain')}</InputLabel>
          <Select
            labelId="domain-input"
            id="domain-select"
            label={t('domain')}
            defaultValue={domain?.[0].baseUrl.slice(8,domain[0].baseUrl.length) ?? ''}
            onChange={selectHandler}
          >
            {
              domain.map(value => {
                let url = value.baseUrl.slice(8,value.baseUrl.length)
                return <MenuItem key={url} value={url}>{url}</MenuItem>
              })
            }

          </Select> 
        </FormControl>

        <Grid container spacing={1} sx={{ mt: '1rem' }} >
          <InputField handleChange={handleChangeInput} label={t('paste_link')} value={link}/>
          <Button className={classes.button} variant="contained" color="primary" onClick={pressHandler} sx={{ mt: '1rem' }} >
            {t('generate')}
          </Button>
        </Grid>
        <Grid container spasing={1} sx={{ mt: '1rem' }}>
          <Autocomplete 
          setHash={e => {
           try {
            const url = new URL(link) 
            url.hash = e
            if (!e) url.hash = ''
            if (url.href[url.href.length -1] === '#') {
              return setLink(url.href.substring(0, url.href.length -1))
            }
            setLink(url.href)
           } catch (error) {
             
           } 
          }}
          setLink={e=> {
            try {
              const url = new URL(link)
              if (!e.val) url.searchParams.delete(e.name)
              else url.searchParams.set(e.name, e.val)
              setLink(url.href)
            } catch (error) {
            }
            }} 
          link={link}
          domainId={domain[0].domainId} />
        </Grid>
      </Paper>
    </main>
  );}
  return (
    <main>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          {t('create_short_link')}
        </Typography>
        <Typography component="p" variant="h5" align="center">
          {t('dont_have_domains')}
        </Typography>
      </Paper>
    </main>
  )
};
