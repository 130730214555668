import {DOMAINS_CHANGE_ALL} from './types'

const initialState = {
  allDomains : []
}

export const domainReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case DOMAINS_CHANGE_ALL:
      return {
        ...state,
        allDomains: payload,
      }     
    default:
      return state;
  }  
}