import { createTheme, ThemeProvider } from '@mui/material/styles';
import Heebo from './fonts/Heebo-VariableFont_wght.ttf'
const customTheme = createTheme({
    palette: {
        background: {
            button: {
                main: '#04BAFF',
                hover: '#00E0FF',
                disable: '#7ab6cd'
            }
        },
    },
    typography: {
        fontFamily: '"Heebo", sans-serif',
        h1: {
          fontWeight: 800,
          fontSize: '45px',
          lineHeight: '53px'
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": [Heebo],
          },
        },
      },
  });

  console.log(customTheme)
  

  const Theme = (props) => {
    return (
        <ThemeProvider  theme={customTheme}>
          { props.children }
        </ThemeProvider>
    )
  }


export default Theme