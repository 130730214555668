import { Container, Grid, Box } from "@mui/material";
import React from "react";
import pixlyLogo from '../../images/pixlyLogo.svg'
import signInImage from '../../images/signIn.png'

const FormContainer = (props) => {

    return (
        <Container maxWidth="lg" style={{ maxWidth: 1440 }} >
            <img src={pixlyLogo} style={{ marginTop: 50, marginLeft: 80, height: 27, width: 124 }} alt="logo Pixly.pro" />
            {/* <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}> */}
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item sx={{ p: 0, pt: 16, pb: 16, minWidth: 300 }} md={7} lg={7} >
                    {props.children}
                </Grid>
                <Grid item xs={12} md={5} sx={{ display: { xs: 'none', lg: 'block' } }} >
                    <Box>
                        <img
                            alt="Beautiful icon"
                            src={signInImage} style={{
                                width: '100%',
                                height: 'auto',
                            }} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default FormContainer